import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  CssBaseline,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import logo from "../assets/images/logo/logo_1_white.png";
import LanguageIcon from "@material-ui/icons/Language";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import Copyright from "../components/Copyright";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 56px)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 64px)",
    },
  },
  startPageStyle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
    },
  },
  contentStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  typographyStyles: {
    fontWeight: "bold",
    margin: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4),
    },
  },
  imageStyles: {
    width: "250px",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
    },
  },
  iconStyles: {
    fontSize: "40px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
    },
  },
}));

const StartPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.startPageStyle}>
        <Grid container className={classes.contentStyle}>
          <Grid item xs={12}>
            <img src={logo} className={classes.imageStyles} alt="logo" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.typographyStyles}>
              Member Portal
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <IconButton
              href="https://www.iaeste.wien"
              target="_blank"
              aria-label="website"
            >
              <LanguageIcon color="secondary" className={classes.iconStyles} />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/iaestevienna"
              target="_blank"
              aria-label="facebook"
            >
              <FacebookIcon color="secondary" className={classes.iconStyles} />
            </IconButton>
            <IconButton
              href="https://www.linkedin.com/company/iaeste-vienna"
              target="_blank"
              aria-label="linkedin"
            >
              <LinkedInIcon color="secondary" className={classes.iconStyles} />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/iaeste_vienna"
              target="_blank"
              aria-label="instagram"
            >
              <InstagramIcon color="secondary" className={classes.iconStyles} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default StartPage;

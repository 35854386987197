import {database} from "../config";

export const createBankDocument = async (user, name) => {
    // get a reference to the Firestore document
    const docRef = database.doc(`/bank-accounts/${user.uid}`);

    // create user object
    const userProfile = {
        accountOwner: name,
        iban: "",
        bic: "",
        bankName: "",
    };

    // write to Cloud Firestore
    return docRef.set(userProfile);
};
export const updateBankDocument = async (bankData) => {
    const docRef = database.doc(`/bank-accounts/${bankData.uid}`);

    return docRef.update(bankData);
};
import firebase from 'firebase/app';
import 'firebase/auth';
import {createBankDocument} from "../firestore/bankAccount";
import {createUserDocument} from "../firestore/userProfile";

export const signup = async ({ firstName, lastName, email, password }) => {
    const resp = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const user = resp.user;

    // await user.updateProfile({ displayName: `${firstName} ${lastName}` });
    await createUserDocument(user, firstName, lastName);
    await createBankDocument(user, `${firstName} ${lastName}`);

    return user;
};

export const logout = () => {
    return firebase.auth().signOut();
};

export const signin = async ({ email, password }) => {
    const resp = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

        return resp.user;
};
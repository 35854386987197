import {database} from "../config";

export const createUserDocument = async (user, firstName, lastName) => {
    // get a reference to the Firestore document
    const docRef = database.doc(`/users/${user.uid}`);

    // create user object
    const userProfile = {
        uid: user.uid,
        email: user.email,
        firstName: firstName,
        lastName: lastName,
        address: "",
        state: "",
        zip: "",
        phone: "",
        ip: "",
    };

    // write to Cloud Firestore
    return docRef.set(userProfile);
};
export const updateUserDocument = async (user) => {
    const docRef = database.doc(`/users/${user.uid}`);

    return docRef.update(user);
};
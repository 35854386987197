import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    margin: theme.spacing(0.5),
    minWidth: "250px",
    borderRadius: 15,
  },
  cardStyleExpense: {
    margin: theme.spacing(0.5),
    minWidth: "250px",
    borderRadius: 0,
  },
  contentStyle: {
    padding: "5px 16px",
  },
  broot: {
    borderRadius: 12,
    minWidth: 256,
    textAlign: "center",
  },
  bheader: {
    textAlign: "center",
    spacing: 10,
  },
  blist: {
    padding: "5px 16px",
  },
  bbutton: {
    margin: theme.spacing(1),
  },
  expenseActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 16px",
    color: theme.palette.text.primary,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  refundActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 16px 3px 16px",
    color: theme.palette.text.primary,
  },
  textCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textAlignRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonStyleDownload: {
    margin: `0 0 0 0`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  buttonSubmitReceipt: {
    margin: `5px 0 5px 0`,
    padding: "0px 0px 0px 0px",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  dividerSubmitReceipt: {
    margin: `15px 0 0px 0`,
    padding: "0px 0px 0px 0px",
  },
  buttonStyleEditDelete: {
    padding: ` ${theme.spacing(0.8)}px ${theme.spacing(0.8)}px`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  expand: {
    transform: "rotate(0deg)",
    margin: ` ${theme.spacing(0)}px ${theme.spacing(0)}px`,
    padding: ` ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  content: {
    paddingTop: "0",
  },
  header: {
    padding: "8px",
  },
}));

export { useStyles };

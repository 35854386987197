import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
// import InputMask from "react-input-mask";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const UserBankDetails = ({ formData, isLoading, onSubmitHandler }) => {
  const { register, setValue, handleSubmit } = useForm();

  useEffect(() => {
    formData && setValue(formData);
  }, [formData, setValue]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <Card>
        <CardHeader subheader="Für Kostenrückerstattungen" title="Bankdaten" />
        <Divider />
        {formData ? (
          <>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Kontoinhaber"
                    name="accountOwner"
                    required
                    variant="outlined"
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="IBAN"
                    name="iban"
                    required
                    variant="outlined"
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />

                  {/* <InputMask
                    mask="aa99 9999 9999 9999 9999 9999"
                    onChange={null}
                    value={null}
                    maskPlaceholder={" "}
                  /> */}

                  
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="BIC"
                    helperText="Für Banken außerhalb Österreichs"
                    name="bic"
                    variant="outlined"
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Name der Bank"
                    name="bankName"
                    variant="outlined"
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button type="submit" color="secondary" variant="contained">
                  Speichern
                </Button>
              </Box>
            )}
          </>
        ) : (
          <CardContent>No data found</CardContent>
        )}
      </Card>
    </form>
  );
};

UserBankDetails.propTypes = {
  isLoading: PropTypes.bool,
};

export default UserBankDetails;

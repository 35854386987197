export const costCenterCategory = [
  {
    labelValue: "hr",
    labelName: "Human Resources",
    description: "",
  },
  {
    labelValue: "ab",
    labelName: "Public Relations (AB)",
    description: "",
  },
  {
    labelValue: "xi",
    labelName: "Exchange Incoming (XI)",
    description: "",
  },
].sort((a, b) => (a.labelValue > b.labelValue ? 1 : -1));

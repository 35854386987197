import React, { useEffect, useState } from "react";
import { database } from "../../firebase/config";
import { Link } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Card, CardHeader, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    margin: theme.spacing(3),
  },
  table: {
    minWidth: 300,
  },
}));

// function createData(name, email) {
//   return { name, email };
// }

// const rows = [
//   createData("Hansi", "hansi@iaeste.wien"),
//   createData("Lore", "lore@iaeste.wien"),
//   createData("Franzi", "franzi@iaeste.wien"),
//   createData("Susi", "susi@iaeste.wien"),
//   createData("Maxi", "maxi@iaeste.wien"),
// ];

const UserList = () => {
  const classes = useStyles();
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const usersRef = database.collection("users");
    const unsubscribe = usersRef.onSnapshot((querySnapshot) => {
      const users = querySnapshot.docs.map((doc) => doc.data());

      setUsers(users);
    });

    return unsubscribe;
  }, []);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader subheader="Alle registrierten Benutzer" title="Benutzer" />
        <Divider />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell> E-Mail </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users ? (
                users.map((user) => (
                  <TableRow key={user.uid}>
                    <TableCell component="th" scope="row">
                    <Link to={`/profile/${user.uid}`}>{user.firstName} {user.lastName}</Link>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No users found</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};

export default UserList;

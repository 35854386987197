import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const UserDetails = ({ formData, isLoading, onSubmitHandler }) => {
  const { register, setValue, handleSubmit, control } = useForm();

  useEffect(() => {
    formData && setValue(formData);
  }, [formData, setValue]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        {formData ? (
          <>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="First name"
                    name="firstName"
                    required
                    variant="outlined"
                    disabled
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last name"
                    name="lastName"
                    required
                    variant="outlined"
                    disabled
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    required
                    variant="outlined"
                    disabled
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    as={
                      <NumberFormat
                        customInput={TextField}
                        format="+## (###) ### ### ###"
                        onValueChange={(v) => {
                          // value without dollar signe
                          // console.log(v.value);
                        }}
                      />
                    }
                    name="phone"
                    variant="outlined"
                    fullWidth
                    label="Mobile Number"
                    control={control}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    variant="outlined"
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    label="Zip"
                    name="zip"
                    variant="outlined"
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    variant="outlined"
                    inputRef={register}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button type="submit" color="secondary" variant="contained">
                  Speichern
                </Button>
              </Box>
            )}
          </>
        ) : (
          <CardContent>No data found</CardContent>
        )}
      </Card>
    </form>
  );
};

UserDetails.propTypes = {
  isLoading: PropTypes.bool,
};

export default UserDetails;

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useSession } from "../../../firebase/UserProvider";
import {
  addReceipDocumentToCollection,

} from "../../../firebase/firestore/receipt";
import {
  costTypeMain,
  costTypeSub,
  paymentPurpose,
  costCenter,
  costCenterCategory,
  approvedby,
} from "../../../assets/finance";

import ExpenseForm from "../../../components/ExpenseForm/ExpenseForm";
import {uploadInvoice} from "../../../firebase/storage/pdfInvoice";

const Expense = (props) => {
  const { user } = useSession();

  const { register, handleSubmit, setValue, errors } = useForm({
    defaultValues: {
      costTypeMain: null,
      costTypeSub: null,
      paymentPurpose: null,
      paymentDescription: null,
      invoiceDate: null,
      amount: null,
      startingPlace: null,
      destination: null,
      kilometersDriven: null,
      passengers: null,
      comment: null,
      costCenter: null,
      approvedby: null,
      verification: null,

    },
  });


  const [invoiceUploadName, setImageUploadName] = React.useState(
    "Keine Datei ausgewählt"
  );
  const [uploadInvoiceProgress, setUploadInvoiceProgress] = React.useState(0);
  const [formSelectsCurrentValue, setFormSelectsCurrentValue] = React.useState({
    costTypeMain: "",
    costTypeSub: "",
    paymentPurpose: "",
    costCenter: "",
    approvedby: "",
  });

  const [passengerSelection, setPassengerSelection] = React.useState([{
    title: `Test Musterperson`,
  }]);

/*  useEffect(
    () =>
      fetch(
        "https://europe-west1-map2dev-644c7.cloudfunctions.net/publicMembers"
      )
        .then((res) => res.json())
        .then((res) => {
          const passengers = res.map((passenger) => ({
            title: `${passenger.firstName} ${passenger.lastName}`,
          }));
          setPassengerSelection(passengers);
          console.log(res);
        })
        .catch((err) => console.log(err)),
    []
  );*/

  useEffect(() => {
    formSelectsCurrentValue.costTypeMain === "fahrtkosten" &&
      ["personentransport", "materialtransport"].includes(
        formSelectsCurrentValue.costTypeSub
      ) &&
      register("passengers");

    formSelectsCurrentValue.costTypeMain !== "fahrtkosten" &&
      ["personentransport", "materialtransport"].includes(
        formSelectsCurrentValue.costTypeSub
      ) &&
      register("passengers");
  }, [
    formSelectsCurrentValue.costTypeMain,
    formSelectsCurrentValue.costTypeSub,
    register,
  ]);

  /*useEffect(() => {
    formSelectsCurrentValue.costTypeMain === "fahrtkosten" &&
      register("passengers", {
        validate: (value) => value.length || "This is required.",
      });
  }, [formSelectsCurrentValue.costTypeMain, register]);*/

  const onFormSelectChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name !== "costTypeMain") {
      setFormSelectsCurrentValue({
        ...formSelectsCurrentValue,
        [name]: value,
      });
    } else {
      setFormSelectsCurrentValue({
        ...formSelectsCurrentValue,
        [name]: value,
        costTypeSub: "",
      });
    }
  };

  const invoiceUploadHandler = async (files) => {
    //console.log(files[0]);
    setImageUploadName(files[0] && files[0].name);

    // setShowUploadProgress(true);
    // const ref = await uploadInvoice(id, files[0], updateInvoiceProgress)
    const ref = await uploadInvoice(1236, files[0], updateInvoiceProgress);

    const downloadUrl = await ref.getDownloadURL();
    // setProfilePictureUrl(downloadUrl);
    // setShowUploadProgress(false);
    //console.log("downloadUrl", downloadUrl);
  };

  const updateInvoiceProgress = (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setUploadInvoiceProgress(progress);
  };

  const onFormSubmit = async (data) => {
    const mapData = {
      costTypeMain: null,
      costTypeSub: null,
      paymentPurpose: null,
      paymentDescription: null,
      invoiceDate: null,
      amount: null,
      startingPlace: null,
      destination: null,
      kilometersDriven: null,
      passengers: null,
      comment: null,
      costCenter: null,
      approvedby: null,
      verification: null,
    };

    console.log("[Expense - onFormSubmit: ]", user.uid, {
      ...mapData,
      ...data,
    });

    data.amount = data.amount.replace(/,/g, '.');

    await addReceipDocumentToCollection(user.uid, data);
    props.history.push(`/finance/refunds`);
  };

  const costTypeSubArray = costTypeSub.filter((item) =>
    item.showOnMainCategorieArray.includes(formSelectsCurrentValue.costTypeMain)
  );

  const onClickHandler = () => {
    props.history.push(`/finance/refunds`);
  };

  const autocompleteOnChangeHandler = (e, options) => {
    setValue("passengers", options);
  };

  return (
    <div>
      <ExpenseForm
        formSelectsCurrentValue={formSelectsCurrentValue}
        onFormSelectChange={onFormSelectChange}
        costTypeMain={costTypeMain}
        costTypeSubArray={costTypeSubArray}
        formSubmitHandler={onFormSubmit}
        paymentPurpose={paymentPurpose}
        invoiceUploadHandler={invoiceUploadHandler}
        invoiceUploadName={invoiceUploadName}
        passengerSelectionArray={passengerSelection}
        costCenterCategory={costCenterCategory}
        approvedby={approvedby}
        costCenter={costCenter}
        onClickHandler={onClickHandler}
        register={register}
        handleSubmit={handleSubmit}
        setValue={setValue}
        autocompleteOnChangeHandler={autocompleteOnChangeHandler}
        errors={errors}
      />
    </div>
  );
};

export default Expense;

import React from "react";
import "./firebase/config";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";

import { UserProvider } from "./firebase/UserProvider";
import ProfileRedirect from "./router/ProfileRedirect";
import PrivateRoute from "./router/PrivateRoute";

import AppBar from "./components/AppBar";
import StartPage from "./pages/StartPage";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Account from "./pages/Account";
import Dashboard from "./pages/Dashboard";
import UserList from "./pages/Admin/UserList";
import ReceiptData from "./pages/ReceiptData";
import Dev from "./pages/Dev";
import Expense from "./pages/Finance/Expense/Expense";
import Refunds from "./pages/Finance/Refunds/Refunds";
import DashboardRedirect from "./router/DashboardRedirect";

const App = () => (
  <UserProvider>
    <BrowserRouter>
      <AppBar />
      <Switch>
        <DashboardRedirect exact path="/" component={StartPage} />
        <ProfileRedirect exact path="/signup" component={SignUp} />
        <ProfileRedirect exact path="/signin" component={SignIn} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/users" component={UserList} />
        <PrivateRoute exact path="/profile/:id" component={Account} />


        <Route exact path="/finance/refunds" component={Refunds} />
        <Route exact path="/finance/refunds/expense/create" component={Expense} />

        <Route exact path="/finance/refunds/receipt-data" component={ReceiptData} />


        <Route exact path="/dev" component={Dev} />

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </UserProvider>
);

export default App;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flexwrap",
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
}));

export { useStyles };

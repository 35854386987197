import React, { useEffect, useState } from "react";
import { database } from "../firebase/config";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useSession } from "../firebase/UserProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Dev = (props) => {
  const classes = useStyles();
  const { user } = useSession();
  const [receipts, setReceipts] = useState(null);

  useEffect(() => {
    const usersRef = database
      .collection("finance")
      .doc(user.uid)
      .collection("receipts");
      
    const unsubscribe = usersRef.onSnapshot((querySnapshot) => {
      const receipts = querySnapshot.docs.map((doc) => doc.data());
      console.log(querySnapshot.size);

      setReceipts(receipts);
    });

    return unsubscribe;
  }, [user.uid]);

  console.log(
    process.env.REACT_APP_DEV_MODE,
    typeof process.env.REACT_APP_DEV_MODE,
    Boolean(process.env.REACT_APP_DEV_MODE),
    receipts
  );
  return (
    <div>
      <Container fixed className={classes.paper}>
        <Link to="/finance/refunds/receipt-data">Receipt data</Link>
        <Link to="/finance/refunds">Refund of expenses</Link>
        <Link to="/finance/refunds/expense/create">Expenses</Link>
        <Link to="/dashboard">Dashboard</Link>
      </Container>

    </div>
  );
};

export default Dev;

import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSession } from "../firebase/UserProvider";
import { logout } from "../firebase/auth/auth";

import {
  AppBar as AppBarUI,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/images/logo/logo_white.png";
import Button from "@material-ui/core/Button";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";

const useStyles = makeStyles((theme) => ({
  typographyStyles: {
    flex: 1,
  },
  offset: theme.mixins.toolbar,
}));

const AppBar = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const location = useLocation();
  const history = useHistory();
  const { user } = useSession();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const login = async () => {
    history.push("/signin");
  };

  const logoutUser = async () => {
    await logout();
    handleClose();
    history.push("/login");
  };

  const goToMyAccount = () => {
    handleClose();
    history.push(`/profile/${user.uid}`);
  };

  const goToDev = () => {
    history.push(`/dev`);
  };

  return (
    <>
      <AppBarUI position="fixed" className={classes.appBarStyles}>
        <Toolbar>
          {location.pathname !== "/" && (
            <>
              <IconButton>
                <Link to="/">
                  <img src={logo} width="35px" alt="logo" />{" "}
                </Link>
              </IconButton>
              <Typography className={classes.typographyStyles}>
                <Link to="/">Mitgliederportal</Link>
              </Typography>
            </>
          )}
          {!!user ? (
            <Grid container justify="flex-end">
              {Boolean(process.env.REACT_APP_DEV_MODE) && (
                <IconButton color="inherit" onClick={goToDev}>
                  <DeveloperModeIcon />
                </IconButton>
              )}
              <IconButton color="inherit" onClick={handleMenu}>
                <AccountCircleIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={goToMyAccount}>My account</MenuItem>
                <MenuItem onClick={logoutUser}>Logout</MenuItem>
              </Menu>
            </Grid>
          ) : (
            <Grid container justify="flex-end">
              <Button variant="outlined" color="inherit" onClick={login}>
                Login
              </Button>
            </Grid>
          )}
        </Toolbar>
      </AppBarUI>
      <div className={classes.offset} />
    </>
  );
};

export default AppBar;

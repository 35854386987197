import {database} from "../config";

export const addReceipDocumentToCollection = async (userId, receiptData) => {
  // get a reference to the Firestore document
  const docRef = database.collection(`finance`).doc(userId).collection('receipts');

  // write to Cloud Firestore
  return docRef.add(receiptData);
};

export const addSubmittedReceipToCollection = async (userId, receiptData) => {
  // get a reference to the Firestore document
  const docRef = database.collection(`finance`).doc(userId).collection('receipts_submitted');

  // write to Cloud Firestore
  return docRef.add(receiptData);
};

export const removeReceipDocumentFromCollection = async (userId, id) => {
  // get a reference to the Firestore document 
  //const docRef = firestore.doc(`/finance/${userId}/receipts/${id}`);
  const docRef = database
      .collection("finance")
      .doc(userId)
      .collection("receipts")
      .doc(id);

  // write to Cloud Firestore
  return docRef.delete();
};

import React from "react";
import clsx from "clsx";
import {
  CardContent,
  CardHeader,
  Fab,
  Grid,
  Typography,
  CardActions,
  Button,
  IconButton,
  Paper, Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Avatar } from "@material-ui/core";
import EuroIcon from "@material-ui/icons/Euro";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Collapse from "@material-ui/core/Collapse";

import { useStyles } from "./RefundItemCardStyles.js";
import ExpenseItemCard from "./ExpenseItemCard/ExpenseItemCard.js";
import Box from "@material-ui/core/Box";

const RefundItemCard = ({ receipts, user, status, onSubmitHandler }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let totalAmount = Number(0);
  receipts &&
    receipts.map(
      (receipt, index) =>
        (totalAmount = Number(totalAmount) + Number(receipt.amount))
    );

  let action;

  if (status === "submitted") {
    action = (
      <Button
        className={classes.buttonStyleDownload}
        variant="text"
        onClick={() => console.log("Button")}
        startIcon={<CloudDownloadIcon />}
      >
        PDF
      </Button>
    );
  } else {
    action = (
    <Button
        component={Link}
        to="/finance/refunds/expense/create"
        variant="contained"
        size="small"
        color="secondary"
        // endIcon={<AddIcon />}
    >
       Ausgabe erfassen
    </Button>
    );
  }

  const refundData = {
    totalAmount: totalAmount,
    displayName: user.displayName,
    date: new Date(),
    memberStatus: "Außerordentliches Mitglied",
    iban: "AT12 3456 7890 1234 5678",
  };

  return (
    <div>
      <Grid container justify="center">
        <Grid item lg={3} md={3} xs={null}></Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Paper className={classes.cardStyle}>
            <CardHeader
              avatar={

                <Avatar size="small" className={classes.expensesAvatar}>
                  <EuroIcon />
                </Avatar>
              }
              title="Kostenerstattung"
              subheader={new Date().toLocaleDateString("de-DE")}
              action={action}
            />
            <CardContent className={classes.contentStyle}>
              <Grid container justify="center">
                <Grid item lg={12} md={12} xs={12}>
                  {/* <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.textCenter}
                  >
                    {`${user.displayName}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.textCenter}
                  >
                    Außerordentliches Mitglied
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.textCenter}
                  >
                    AT12 3456 7890 1234 5678
                  </Typography> */}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.refundActions}>

              <div>

                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
                </div>
                {/*<div>
                {status !== "submitted" && (
                    <Button
                        component={Link}
                        to="/finance/refunds/expense/create"
                        variant="outlined"
                        size="small"
                        color="secondary"
                    >
                      Ausgabe erfassen <AddIcon />
                    </Button>
                )}
              </div>*/}
              <div>
              <Typography
                  variant="h5"
                  component="p"
                  fontWeight="fontWeightBold"
                  className={classes.textCenter}
              >
                € {Number(totalAmount).toFixed(2)}
              </Typography>
              </div>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Grid container justify="center">
                {receipts &&
                  receipts.map((receipt, index) => (
                    <ExpenseItemCard
                      key={index}
                      receipt={receipt}
                      index={index}
                    />
                  ))}
              </Grid>
            </Collapse>

            <Divider className={classes.dividerSubmitReceipt} />

            <div className={classes.textCenter}>
              {status !== "submitted" && (
                  <Button
                      className={classes.buttonSubmitReceipt}
                      variant="text"
                      onClick={() => onSubmitHandler(refundData)}
                  >
                    Einreichen
                  </Button>
              )}
            </div>

          </Paper>

        </Grid>
        <Grid item lg={3} md={3} xs={null}></Grid>
      </Grid>

      <Fab
        component={Link}
        to="/finance/refunds/expense/create"
        variant="circular"
        size="medium"
        color="secondary"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default RefundItemCard;

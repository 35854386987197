import React from "react";

import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";

import { useStyles } from "./ExpenseFormStyles.js";
import CostTypeSelect from "./CostTypeSelect/CostTypeSelect.js";
import InternDetails from "./InternDetails/InternDetails.js";
import InvoiceData from "./InvoiceData/InvoiceData.js";

const ExpenseForm = ({
  formSelectsCurrentValue,
  onFormSelectChange: onSelectChangeHandler,
  costTypeMain,
  costTypeSubArray,
  formSubmitHandler,
  paymentPurpose,
  invoiceUploadHandler,
  invoiceUploadName,
  passengerSelectionArray,
  costCenterCategory,
  approvedby,
  costCenter,
  onClickHandler,
  register: useFormInputRefRegister,
  handleSubmit,
  setValue,
  errors,
  autocompleteOnChangeHandler
}) => {
  const classes = useStyles();

  return (
    <div>
      <form onSubmit={handleSubmit(formSubmitHandler)} className={classes.root}>
        <Card>
          <CardHeader
            title="Ausgabe erfassen"
            subheader="Kosten, die Dir vom Verein ersetzt bzw. zurückgezahlt werden sollen"
          />

          <Divider />

          <CardContent>
            <CostTypeSelect
              selectedValueObject={formSelectsCurrentValue}
              onSelectChangeHandler={onSelectChangeHandler}
              costTypeMainArray={costTypeMain}
              costTypeSubArray={costTypeSubArray}
              useFormInputRefRegister={useFormInputRefRegister}
            />

            <Divider variant="middle" className={classes.dividerMiddle} />

            <InvoiceData
              selectedValueObject={formSelectsCurrentValue}
              onSelectChangeHandler={onSelectChangeHandler}
              useFormInputRefRegister={useFormInputRefRegister}
              paymentPurposeArray={paymentPurpose}
              invoiceUploadHandler={invoiceUploadHandler}
              invoiceUploadName={invoiceUploadName}
              passengerSelectionArray={passengerSelectionArray}
              setValue={setValue}
              autocompleteOnChangeHandler={autocompleteOnChangeHandler}
              errors={errors}
            />

            <Divider variant="middle" className={classes.dividerMiddle} />

            <InternDetails
              selectedValueObject={formSelectsCurrentValue}
              costCenterCategoryArray={costCenterCategory}
              approvedbyArray={approvedby}
              costCenterArray={costCenter}
              onSelectChangeHandler={onSelectChangeHandler}
              useFormInputRefRegister={useFormInputRefRegister}
            />
          </CardContent>

          <Divider />

          <CardActions className={classes.cardActions}>
            <Button
              onClick={onClickHandler}
              color="primary"
            >
              Abbrechen
            </Button>
            <Button type="submit" color="secondary" variant="contained">
              Speichern
            </Button>
          </CardActions>
        </Card>
      </form>
    </div>
  );
};

export default ExpenseForm;

import React, { useState, useEffect } from "react";
import ProfileAvatar from "../components/User/ProfileAvatar";
import UserBankDetails from "../components/User/UserBankDetails";
import UserDetails from "../components/User/UserDetails";
import { database } from "../firebase/config";
import { useParams } from "react-router-dom";
import { useSession } from "../firebase/UserProvider";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "material-react-toastify";
import {getDownloadUrl, uploadImage} from "../firebase/storage/profileImage";
import {updateBankDocument} from "../firebase/firestore/bankAccount";
import {updateUserDocument} from "../firebase/firestore/userProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
const Account = () => {
  const classes = useStyles();

  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const id = useParams().id;

  const { user } = useSession();
  const [userDocument, setUserDocument] = useState(null);
  const [isLoadingUser, setLoadingUser] = useState(false);
  const [isLoadingBank, setLoadingBank] = useState(false);
  const [formUserData, setUserData] = useState(false);
  const [formBankData, setBankData] = useState(false);

  useEffect(() => {
    getDownloadUrl(id).then((url) => !!url && setProfilePictureUrl(url));
  }, [id]);

  useEffect(() => {
    const docRef = database.collection("users").doc(id);
    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const documentData = doc.data();
        setUserDocument(documentData);

        const formData = Object.entries(documentData).map((entry) => ({
          [entry[0]]: entry[1],
        }));

        setUserData(formData);
      }
    });
    return unsubscribe;
  }, [user.uid, setUserData, id]);

  useEffect(() => {
    const docRef = database.collection("bank-accounts").doc(id);
    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const documentData = doc.data();
        //setUserDocument(documentData);

        const formData = Object.entries(documentData).map((entry) => ({
          [entry[0]]: entry[1],
        }));

        setBankData(formData);
      }
    });
    return unsubscribe;
  }, [user.uid, setBankData, id]);

  const pictureUploadHandler = async (files) => {
    setShowUploadProgress(true);
    const ref = await uploadImage(id, files[0], updateProgress);
    const downloadUrl = await ref.getDownloadURL();
    setProfilePictureUrl(downloadUrl);
    setShowUploadProgress(false);
  };

  const updateProgress = (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setUploadProgress(progress);
  };

  const onSubmitUserHandler = async (data) => {
    try {
      setLoadingUser(true);
      await updateUserDocument({ uid: id, ...data });
    } catch (error) {
      console.log(error);
      toast.error("Ups, da ist etwas schiefgelaufen.");
    } finally {
      setLoadingUser(false);
      toast.success("Deine Profildaten wurden erfolgreich gespeichert.");
    }
  };

  const onSubmitBankHandler = async (data) => {
    try {
      setLoadingBank(true);
      await updateBankDocument({ uid: id, ...data });
    } catch (error) {
      console.log(error);
      toast.error("Ups, da ist etwas schiefgelaufen.");
    } finally {
      setLoadingBank(false);
      toast.success("Deine Bankdaten wurden erfolgreich gespeichert.");
    }
  };

  const propsProfileAvatar = {
    firstName: userDocument && userDocument.firstName,
    lastName: userDocument && userDocument.lastName,
    profilePictureUrl,
    pictureUploadHandler,
    showUploadProgress,
    uploadProgress,
  };

  const propsUserDetails = {
    formData: formUserData,
    isLoading: isLoadingUser,
    onSubmitHandler: onSubmitUserHandler,
  };

  const propsBankData = {
    formData: formBankData,
    isLoading: isLoadingBank,
    onSubmitHandler: onSubmitBankHandler,
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          display="flex"
          justify="flex-end"
          spacing={3}
        >
          <Grid item lg={4} md={6} xs={12}>
            <ProfileAvatar {...propsProfileAvatar} />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <UserDetails {...propsUserDetails} />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <UserBankDetails {...propsBankData} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Account;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dividerFullWidth: {
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(
      1
    )}px ${theme.spacing(0)}px`,
    color: theme.palette.primary.main,
  },
  formControl: {
    margin: `${theme.spacing(1)}px 0`,
    minWidth: "100%",
  },
  fileInput: {
    display: "none",
  },
  buttonStyleUpload: {
    margin: `${theme.spacing(1)}px 0 0 0`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  invoiceUploadName: {
    margin: `0 0 ${theme.spacing(1)}px 0`,
    padding: 0,
    textAlign: "center",
    fontSize: "9px",
  },
}));

export { useStyles };
import { useEffect, useState } from "react";
import RefundItemCard from "../../../components/Refund/RefundItemCard";
import { database } from "../../../firebase/config";
import { useSession } from "../../../firebase/UserProvider";

import {
  addSubmittedReceipToCollection,
  removeReceipDocumentFromCollection,
} from "../../../firebase/firestore/receipt";

import { useStyles } from "./RefundsStyles.js";

const Refunds = () => {
  const classes = useStyles();
  const { user } = useSession();
  const [receipts, setReceipts] = useState(null);
  const [receiptsSubmitted, setReceiptsSubmitted] = useState(null);

  useEffect(() => {
    const receiptRef = database
      .collection("finance")
      .doc(user.uid)
      .collection("receipts");

    const unsubscribe = receiptRef.onSnapshot((querySnapshot) => {
      const receipts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        uid: user.uid,
      }));
      console.log(receipts);
      setReceipts(receipts);
    });

    return unsubscribe;
  }, [user.uid]);

  useEffect(() => {
    const receiptRef = database
      .collection("finance")
      .doc(user.uid)
      .collection("receipts_submitted");

    const unsubscribe = receiptRef.onSnapshot((querySnapshot) => {
      const receipts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        uid: user.uid,
      }));
      console.log(receipts);
      setReceiptsSubmitted(receipts);
    });

    return unsubscribe;
  }, [user.uid]);

  const onSubmitHandler = async (data) => {
    const mapData = {
      costTypeMain: null,
      costTypeSub: null,
      paymentPurpose: null,
      paymentDescription: null,
      invoiceDate: null,
      amount: null,
      startingPlace: null,
      destination: null,
      kilometersDriven: null,
      passengers: null,
      comment: null,
      costCenter: null,
      approvedby: null,
      verification: null,
    };

    const d = {
      ...data,
      ...{ receipts: receipts },
    };

    //console.log("[Refunds - onSubmit: ]", user.uid, d);
    receipts && receipts.map((receipt) =>
      removeReceipDocumentFromCollection(user.uid, receipt.id)
    )

    await addSubmittedReceipToCollection(user.uid, d);
  };

  return (
    <div className={classes.root}>
      <RefundItemCard
        receipts={receipts}
        user={user}
        status={"draft"}
        onSubmitHandler={onSubmitHandler}
      />

      {receiptsSubmitted &&
        receiptsSubmitted.map((refund, index) => {
          console.log(refund);
          return (
            <RefundItemCard
              key={index}
              receipts={refund.receipts}
              user={user}
              status={"submitted"}
            />
          );
        })}
    </div>
  );
};

export default Refunds;

import React from "react";

import {
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";

import { useStyles } from "./InternDetailsStyles.js";

const InternDetails = ({
  selectedValueObject,
  costCenterCategoryArray,
  approvedbyArray,
  costCenterArray,
  onSelectChangeHandler,
  useFormInputRefRegister,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Typography
        className={classes.dividerFullWidth}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Vereinsinterne Details
      </Typography>

      <Grid container spacing={1}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            multiline
            rows={2}
            rowsMax={3}
            className={classes.formControl}
            fullWidth
            label="Kommentar bzw. Anmerkungen"
            name="comment"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            inputRef={useFormInputRefRegister}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item lg={3} md={3} xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel shrink>Kostenstelle</InputLabel>

            <Select
              notched
              native
              value={selectedValueObject.approvedBy}
              onChange={onSelectChangeHandler}
              name="costCenter"
              label="Kostenstelle"
              inputRef={useFormInputRefRegister}
            >
              <option key={"uniqeCC"} aria-label="None" value="" />
              {costCenterCategoryArray &&
                costCenterArray &&
                costCenterCategoryArray.map((category, indexMain) => (
                  <optgroup key={indexMain + 1} label={category.labelName}>
                    {costCenterArray
                      .filter((item) => category.labelValue === item.category)
                      .map((item, index) => (
                        <option key={index + 1} value={item.labelValue}>
                          {item.labelName}
                        </option>
                      ))}
                  </optgroup>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={3} md={3} xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel shrink>Genehmigt durch</InputLabel>
            <Select
              notched
              native
              value={selectedValueObject.approvedby}
              onChange={onSelectChangeHandler}
              name="approvedby"
              label="Genehmigt durch"
              inputRef={useFormInputRefRegister}
            >
              <option key={"approvedby"} aria-label="None" value="" />
              {approvedbyArray.map((item, index) => (
                <option key={index} value={item.labelValue}>
                  {item.labelName}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={6} md={6} xs={12}>
          <TextField
            multiline
            rowsMax={2}
            className={classes.formControl}
            fullWidth
            label="Nachweis"
            name="verification"
            // required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            inputRef={useFormInputRefRegister}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InternDetails;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    margin: theme.spacing(3),
    maxWidth: "100%",
  },
  formControl: {
    margin: `${theme.spacing(1)}px 0`,
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dividerMiddle: {
    margin: `${theme.spacing(1)}px 0 0 0`,
  },
  paper: {
    margin: "5px 0 10px 0 ",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export { useStyles };

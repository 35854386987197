import {storage} from "../config";

export const uploadInvoice = (userId, file, progress) => {
    return new Promise((resolve, reject) => {
        // create file reference
        const filePath = `/invoice/${userId}`;
        const fileRef = storage.ref().child(filePath);

        // upload task
        const uploadTask = fileRef.put(file);

        uploadTask.on(
            "state_changed",
            (snapshot) => progress(snapshot),
            (error) => reject(error),
            () => {
                resolve(uploadTask.snapshot.ref);
            }
        );
    });
};
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { addReceipDocumentToCollection } from "../firebase/firestore/receipt";
import { makeStyles } from "@material-ui/core/styles";
import { useSession } from "../firebase/UserProvider";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputAdornment from "@material-ui/core/InputAdornment";

import {
  paymentPurpose,
} from "../assets/finance";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    margin: theme.spacing(3),
    maxWidth: "100%",
  },
  input: {
    display: "none",
  },
  section1: {
    margin: theme.spacing(4, 2),
  },
  section2: {
    margin: theme.spacing(4, 2),
  },
}));

const ReceiptData = ({ isLoading, onSubmitHandler }) => {
  const classes = useStyles();
  const { user } = useSession();
  const { register, errors, setValue, handleSubmit } = useForm();

  const formData = null;

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  useEffect(() => {
    formData && setValue(formData);
  }, [formData, setValue]);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    console.log("handleChange", event.target, state);
  };

  const onSubmit = async (data) => {
    console.log("[-onSubmit ]", data, user.uid);

    await addReceipDocumentToCollection(user.uid, data);
  };

  return (
    <form
      autoComplete="off"
      noValidate
      // onSubmit={handleSubmit(onSubmitHandler)}
      onSubmit={handleSubmit(onSubmit)}
      className={classes.root}
    >
      <Card>
        <CardHeader
          title="Ausgabe erfassen"
          subheader="Kosten, die Dir vom Verein ersetzt bzw. zurückgezahlt werden sollen"
        />

        <Divider />

        <CardContent>
          <div className={classes.section1}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={6} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="purpose-native-helper">
                    Zweck der Ausgabe
                  </InputLabel>
                  <NativeSelect
                    value={state.purpose}
                    onChange={handleChange}
                    inputProps={{
                      name: "paymentPurpose1",
                      id: "purpose-native-helper",
                    }}
                    inputRef={register}
                  >
                    <option value={null}>select one</option>
                    {paymentPurpose.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText>
                    Für welchen Anlass ist die Ausgabe?
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item lg={3} md={6} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="purpose-native-helper">
                    Zweck der Ausgabe 2
                  </InputLabel>
                  <NativeSelect
                    value={state.purpose}
                    onChange={handleChange}
                    inputProps={{
                      name: "paymentPurpose2",
                      id: "purpose-native-helper",
                    }}
                    inputRef={register}
                  >
                    <option value={null}>select one</option>
                    {paymentPurpose.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText>
                    Für welchen Anlass ist die Ausgabe?
                  </FormHelperText>
                </FormControl>
              </Grid>
              
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Beschreibung Zweck"
                  name="description"
                  required
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>

              <Grid item lg={3} md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Rechnungsdatum"
                  name="invoiceDate"
                  type="date"
                  required
                  variant="outlined"
                  inputRef={register({ required: false })}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.invoiceDate}
                />
              </Grid>
              {/* <Grid item lg={4} md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Firma"
                  name="company"
                  helperText="Wo habe ich das Produkt bzw. die Dienstleistung her?"
                  required
                  variant="outlined"
                  inputRef={register}
                />
              </Grid> */}
              <Grid item lg={3} md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Betrag"
                  name="amount"
                  required
                  variant="outlined"
                  inputRef={register({ required: false })}
                  type="number"
                  error={!!errors.amount}
                  helperText={errors.amount && "Amount is required"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* <Grid item >
              <input
                accept=".png,.jpg,.pdf"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="secondary" component="span">
                  Upload
                </Button>
              </label>
            </Grid> */}

              {/* Upload of a pdf invoice */}
              <Grid item lg={4} md={6} xs={12}>
              <Button
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
              >
                Upload Rechnung
              </Button>
            </Grid>
            </Grid>
          </div>

          <Divider variant="middle" />
          <div className={classes.section2}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} xs={6}>
                <TextField
                  fullWidth
                  label="Genehmigt durch"
                  name="approved1"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Beschlussnummer, Name+Unterschrift oder Sonst. Bemerkung"
                  name="approved2"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
              <Grid item lg={3} md={3} xs={6}>
                <TextField
                  fullWidth
                  label="Kostenstelle"
                  name="costCenter"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>

              <Grid item lg={4} md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Anmerkung"
                  name="comment"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </div>
        </CardContent>

        <Divider />

        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button type="submit" color="default" variant="contained">
            Abbrechen
          </Button>
          <Button type="submit" color="secondary" variant="contained">
            Speichern
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default ReceiptData;

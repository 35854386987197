export const paymentPurpose = [
  {
    labelValue: "sonstiges",
    labelName: "Sonstiges",
    description: "",
    showOnMainCategorieArray: ["all"],
    created_at: null,
    updated_at: null,
  },
  {
    labelValue: "eventintern",
    labelName: "Event (Vienna)",
    description: "Internes Event der Vienna",
    showOnMainCategorieArray: ["all"],
    created_at: null,
    updated_at: null,
  },
  {
    labelValue: "eventiaeste",
    labelName: "Event (IAESTE)",
    description: "Event das von IAESTE veranstaltet wird",
    showOnMainCategorieArray: ["all"],
    created_at: null,
    updated_at: null,
  },
  {
    labelValue: "eventextern",
    labelName: "Event (extern)",
    description: "Externe Event, das nicht von IAESTE abgehalten wird",
    showOnMainCategorieArray: ["all"],
    created_at: null,
    updated_at: null,
  },
].sort((a, b) => (a.labelValue > b.labelValue ? 1 : -1));

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardStyleExpense: {
    margin: ` ${theme.spacing(0.3)}px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.background.lightgrey,
    minWidth: "250px",
    borderRadius: 10,
  },
  Header: {
    padding: "14px 14px 0px 14px"
  },
  blist: {
    padding: "2px 14px",
    lineHeight: "1",
  },
  expenseActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 14px 3px 8px",
    color: theme.palette.text.primary,
  },
  buttonStyleEditDelete: {
    padding: ` ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.background.lightgrey,
    color: theme.palette.primary.main,
  },
  buttonStyleInfo: {
    padding: ` ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.background.lightgrey,
    color: theme.palette.primary.main,
  },
  bold: {
    fontWeight: 800,
},
}));

export { useStyles };
import React, { useRef } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";

import { useStyles } from "./InvoiceDataStyles.js";

const InvoiceData = ({
  selectedValueObject,
  paymentPurposeArray,
  passengerSelectionArray,
  errors,
  invoiceUploadName,
  invoiceUploadHandler,
  onSelectChangeHandler,
  autocompleteOnChangeHandler,
  useFormInputRefRegister,
}) => {
  const classes = useStyles();
  const fileInput = useRef(null);

  return (
    <div>
      <Typography
        className={classes.dividerFullWidth}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Rechnungsdetails
      </Typography>

      <Grid container spacing={1}>
        <Grid item lg={3} md={3} xs={12}>
          {/* Payment purpose - Zweck */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel shrink>Zweck</InputLabel>

            <Select
              notched
              native
              value={selectedValueObject.paymentPurpose}
              onChange={onSelectChangeHandler}
              name="paymentPurpose"
              label="Zweck"
              inputRef={useFormInputRefRegister}
              disabled={false}
            >
              <option key={"paymentPurpose"} aria-label="None" value="" />
              {selectedValueObject.costTypeMain &&
                paymentPurposeArray
                  // To-do: refactor
                  .filter(
                    (item) =>
                      item.showOnMainCategorieArray.includes(
                        selectedValueObject.costTypeMain
                      ) || item.showOnMainCategorieArray.includes("all")
                  )
                  .map((item, index) => (
                    <option key={index} value={item.labelValue}>
                      {item.labelName}
                    </option>
                  ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Payment description - Beschreibung */}
        <Grid item lg={9} md={9} xs={12}>
          <TextField
            className={classes.formControl}
            multiline
            rowsMax={2}
            fullWidth
            label="Beschreibung"
            name="paymentDescription"
            required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            inputRef={useFormInputRefRegister}
          />
        </Grid>
      </Grid>

      {/* Ivoice date - Rechnungsdatum */}
      <Grid container spacing={1}>
        <Grid item lg={3} md={3} xs={12}>
          <TextField
            className={classes.formControl}
            fullWidth
            label="Rechnungsdatum"
            name="invoiceDate"
            type="date"
            // required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            inputRef={useFormInputRefRegister}
          />
        </Grid>

        {/* Amount - Betrag */}
        <Grid item lg={3} md={3} xs={12}>
          <TextField
            className={classes.formControl}
            fullWidth
            label="Betrag"
            name="amount"
            required
            variant="outlined"
            //type="number" only integer
            // helperText={"Amount is required"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            inputRef={useFormInputRefRegister}
            disabled={selectedValueObject.costTypeMain === "fahrtkosten"}
          />
        </Grid>

        {!["personentransport", "materialtransport"].includes(
          selectedValueObject.costTypeSub
        ) && (
          /* Invoice upload - Beleg hochladen */
          <Grid item lg={6} md={3} xs={12}>
            <input
              className={classes.fileInput}
              type="file"
              accept="image/*,application/pdf"
              ref={fileInput}
              onChange={(e) => invoiceUploadHandler(e.target.files)}
            ></input>

            <Button
              className={classes.buttonStyleUpload}
              fullWidth
              variant="text"
              onClick={() => fileInput.current.click()}
              startIcon={<CloudUploadIcon />}
            >
              Beleg hochladen
            </Button>
            <p className={classes.invoiceUploadName}>{invoiceUploadName}</p>
          </Grid>
        )}

        {/* Only if subtype is Personentransport oder Materialtransport */}
        {selectedValueObject.costTypeMain === "fahrtkosten" &&
          ["personentransport", "materialtransport"].includes(
            selectedValueObject.costTypeSub
          ) && (
            <>
              {/* Startin place - Startort */}
              <Grid item lg={3} md={3} xs={12}>
                <TextField
                  className={classes.formControl}
                  multiline
                  rowsMax={2}
                  fullWidth
                  label="Startort"
                  name="startingPlace"
                  // required
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputRef={useFormInputRefRegister}
                />
              </Grid>

              {/* Destination - Zielort */}
              <Grid item lg={3} md={3} xs={12}>
                <TextField
                  className={classes.formControl}
                  multiline
                  rowsMax={2}
                  fullWidth
                  label="Zielort"
                  name="destination"
                  // required
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputRef={useFormInputRefRegister}
                />
              </Grid>
            </>
          )}
      </Grid>

      {/* Only if subtype is Personentransport oder Materialtransport */}
      {selectedValueObject.costTypeMain === "fahrtkosten" &&
        ["personentransport", "materialtransport"].includes(
          selectedValueObject.costTypeSub
        ) && (
          <>
            <Grid container spacing={1}>
              {/* Kilometers driven - Gesamtkilometer */}
              <Grid item lg={3} md={3} xs={12}>
                <TextField
                  className={classes.formControl}
                  fullWidth
                  label="Gesamtkilometer"
                  name="kilometersDriven"
                  // required
                  variant="outlined"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  inputRef={useFormInputRefRegister}
                />
              </Grid>

              {/* Passengers - MitfahrerInnen */}
              <Grid item lg={9} md={9} xs={12}>
                <Autocomplete 
                  className={classes.formControl}
                  multiple
                  options={passengerSelectionArray}
                  // defaultValue={[passengers[3]]}
                  getOptionLabel={(option) => option.title}
                  onChange={autocompleteOnChangeHandler}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors?.autocomplete)}
                      helperText={errors?.autocomplete?.message}
                      variant="outlined"
                      label="MitfahrerInnen"
                      name="passengers"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}
    </div>
  );
};

export default InvoiceData;

import React, { useEffect, useState } from "react";
import { database } from "../firebase/config";
import { useSession } from "../firebase/UserProvider";

import {
  Button,
  CardContent,
  Grid,
  Typography,
  Divider,
  Paper,
  CardActionArea,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowForward from '@material-ui/icons/ArrowForward';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { useStyles } from "./DashboardStyles.js";
import { Alert } from "@material-ui/lab";

const Dashboard = () => {
  const classes = useStyles();
  const { user } = useSession();
  const [receiptCount, setReceiptCount] = useState(0);
  const [receiptSubmittedCount, setReceiptSubmittedCount] = useState(0);
  const [receipts, setReceipts] = useState(null);
  const [userData, setUserData] = useState(false);

  const userId = user && user.uid;

  useEffect(() => {
    if (!userId) return;

    const ref = database
      .collection("finance")
      .doc(userId)
      .collection("receipts");

    const unsubscribe = ref.onSnapshot((querySnapshot) => {
      const count = querySnapshot.size;

      setReceiptCount(count);

      const receipts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        uid: user.uid,
      }));

      setReceipts(receipts);
    });

    return unsubscribe;
  }, [userId]);

  useEffect(() => {
    if (!userId) return;

    const ref = database
        .collection("finance")
        .doc(userId)
        .collection("receipts_submitted");

    const unsubscribe = ref.onSnapshot((querySnapshot) => {
      const count = querySnapshot.size;

      setReceiptSubmittedCount(count);
    });

    return unsubscribe;
  }, [userId]);

  useEffect(() => {
    const docRef = database.collection("users").doc(userId);
    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const documentData = doc.data();
        setUserData(documentData);
      }
    });
    return unsubscribe;
  }, [userId]);

  let totalAmount = Number(0);
  receipts &&
  receipts.map(
      (receipt, index) =>
          (totalAmount = Number(totalAmount) + Number(receipt.amount))
  );

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item lg={10} md={8} sm={8} xs={12}>
          <Paper className={classes.dashboardCards}>
            <CardContent>
              {/* <Avatar
                  className={classes.avatar}
                  src={profilePlaceholder}>
                </Avatar> */}
              <Typography
                variant="h4"
                color="textPrimary"
                component="p"
                className={classes.textMargin}
              >
                {/* ToDo: Fix displayname */}
                Hallo {userData.firstName}, Willkommen im Mitgliederportal! <br />
              </Typography>
              <Typography
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.textMargin}
              >
                Rolle: Gast, E-Mail: {user.email}
              </Typography>
              <Divider />
              <Typography className={classes.textMargin}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec tincidunt praesent semper feugiat nibh sed pulvinar proin gravida.
              </Typography>
            </CardContent>

            <Alert severity="warning" action={
              <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  component={Link}
                   to={`${"/profile/"}${userId}`}
              >
                Zum Account
              </Button>
            }>Die Profilinformationen und Bankdaten müssen vorhanden sein, damit Kosten ohne Fehler eingereicht werden können!</Alert>

          </Paper>
        </Grid>
        <Grid item lg={10} md={8} sm={8} xs={12}>
          <Paper className={classes.dashboardCards}>
            <CardContent>
              <div>
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    component="p"
                  >
                    Meine Finanzen

                  </Typography>
                  {/*<Button type="submit" color="secondary" variant="contained">*/}
                    <Button
                        className={classes.buttonMenuLink}
                        component={Link}
                        to="/finance/refunds"
                        endIcon={<ArrowRightIcon />}
                    >
                    Rückerstattungen
                  </Button>
                </Box>

                <Divider />
                <Typography className={classes.textMargin}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec tincidunt praesent semper feugiat nibh sed pulvinar proin gravida.
                </Typography>
              </div>
              <div>
                <Grid container justify="center">
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Paper className={classes.financeCards}>
                      <CardActionArea
                          component={Link}
                          to="/finance/refunds/expense/create">
                        <CardContent>
                          <Grid container direction="row" alignItems="center">
                            <Grid item>
                              <AddIcon />
                            </Grid>
                            <Grid item>
                              <Typography>
                                Ausgabe erfassen
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Paper>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Paper className={classes.financeCards}>
                      <CardActionArea
                          // component={Link}
                          // to="/finance/refunds"
                          >
                        <CardContent>
                          <Grid container direction="row" alignItems="center">
                            <Grid item>
                              <ArrowForward />
                            </Grid>
                            <Grid item>
                              <Typography>
                                Kosten einreichen (Coming soon)
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Paper>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Paper className={classes.financeInfoCards}>
                      <CardContent>
                        <Typography variant="h4">{receiptCount}</Typography>
                        <Typography variant="body2">Erfasste Ausgaben</Typography>
                      </CardContent>
                    </Paper>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Paper className={classes.financeInfoCards}>
                      <CardContent>
                        <Typography variant="h4">{totalAmount} €</Typography>
                        <Typography variant="body2">Offener Betrag</Typography>
                      </CardContent>
                    </Paper>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Paper className={classes.financeInfoCards}>
                      <CardContent>
                        <Typography variant="h4">{receiptSubmittedCount}</Typography>
                        <Typography variant="body2">Eingereichte Rechnungen</Typography>
                      </CardContent>
                    </Paper>
                  </Grid>
                </Grid>
              </div>

            </CardContent>

          </Paper>
        </Grid>
      </Grid>
    </div >
  );
};

export default Dashboard;

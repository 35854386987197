export const costCenter = [
    {
      labelValue: "hrverp",
      labelName: "Verpflegung",
      description: "",
      category: 'hr',
      created_at: null,
      updated_at: null,
    },{
      labelValue: "hrson",
      labelName: "Sonstiges",
      description: "",
      category: 'hr',
      created_at: null,
      updated_at: null,
    },{
      labelValue: "abma",
      labelName: "Marketing",
      description: "",
      category: 'ab',
      created_at: null,
      updated_at: null,
    },{
      labelValue: "abver",
      labelName: "Verpflegung",
      description: "",
      category: 'ab',
      created_at: null,
      updated_at: null,
    },{
      labelValue: "xisr",
      labelName: "Summer Reception",
      description: "",
      category: 'xi',
      created_at: null,
      updated_at: null,
    },
].sort((a, b) => (a.labelValue > b.labelValue ? 1 : -1));
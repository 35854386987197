export const costTypeSub = [
    {
        abbreviationCode: '',
        labelValue: 'sonstiges',
        labelName: 'Sonstiges',
        description: 'Ausgaben für Verpflegung für andere Events',
        showOnMainCategorieArray: ['verpflegung'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'misi',
        labelName: 'MiSi',
        description: 'Ausgaben für Snacks für die Misi',
        showOnMainCategorieArray: ['verpflegung'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'teamtreffen',
        labelName: 'Teamtreffen/Arbeitstreffen',
        description: 'Treffen um zusammen etwas für den Verein zu arbeiten.',
        showOnMainCategorieArray: ['verpflegung'],
        created_at: null,
        updated_at: null,
    },
/*    {
        abbreviationCode: '',
        labelValue: 'personentransport',
        labelName: 'Personentransport (Privatauto)',
        description: '',
        showOnMainCategorieArray: ['fahrtkosten'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'materialtransport',
        labelName: 'Materialtransport (Privatauto)',
        description: '',
        showOnMainCategorieArray: ['fahrtkosten'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'oefentlicheverkehrsmittel',
        labelName: 'Öffentliche Verkehrsmittel (Bus, Bahn, Flug, ...)',
        description: '',
        showOnMainCategorieArray: ['fahrtkosten'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'mietauto',
        labelName: 'Mietauto',
        description: '',
        showOnMainCategorieArray: ['fahrtkosten'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'fahrtnebenkosten',
        labelName: 'Fahrtnebenkosten (Garage, Parkticket, Maut, ...)',
        description: '',
        showOnMainCategorieArray: ['fahrtkosten'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'movi',
        labelName: 'MoVi',
        description: 'Ausgaben für Essen oder Trinken am MoVi',
        showOnMainCategorieArray: ['verpflegung'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'twinning',
        labelName: 'Twinning',
        description: 'Ausgaben für Verpflegung beim Twinning',
        showOnMainCategorieArray: ['verpflegung'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'inning',
        labelName: 'Inning',
        description: 'Ausgaben für Verpflegung beim Inning',
        showOnMainCategorieArray: ['verpflegung'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'cocon',
        labelName: 'CoCon',
        description: 'Teilnahmegebühr CoCon',
        showOnMainCategorieArray: ['teilnahmegebuehr'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'cec',
        labelName: 'CEC',
        description: 'Teilnahmegebühr CEC',
        showOnMainCategorieArray: ['teilnahmegebuehr'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'jump',
        labelName: 'JUMP',
        description: 'Teilnahmegebühr JUMP',
        showOnMainCategorieArray: ['teilnahmegebuehr'],
        created_at: null,
        updated_at: null,
    },
    {
        abbreviationCode: '',
        labelValue: 'ac',
        labelName: 'AC',
        description: 'Teilnahmegebühr AC',
        showOnMainCategorieArray: ['teilnahmegebuehr'],
        created_at: null,
        updated_at: null,
    },*/
].sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flexwrap",
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  dashboardCards: {
    minWidth: "150px",
    borderRadius: 5,
    marginTop: theme.spacing(3),
  },
  financeCards: {
    minWidth: "50px",
    borderRadius: 0,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.lightgrey,
  },
  financeInfoCards: {
    minWidth: "50px",
    borderRadius: 0,
    backgroundColor: theme.palette.background.lightgrey,
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  textCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textMargin: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  buttonMenuLink: {
    margin: `0 0 0 0`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },

  /* 
  avatar: {
    height: 55,
    width: 55,
  },
  cardStyle: {
    margin: theme.spacing(3),
    minWidth: "150px",
  },   
  userAvatar: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  expensesAvatar: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
  }, */
}));

export { useStyles };
import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import profilePlaceholder from "../../assets/images/profile-placeholder.png";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  avatar: {
    height: 100,
    width: 100,
  },
  buttonStyle: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  fileInput: {
    display: "none",
  },
  upload: {
    justifyContent: "center",
  },
}));

const ProfileAvatar = ({
  firstName,
  lastName,
  profilePictureUrl,
  pictureUploadHandler,
  showUploadProgress,
  uploadProgress,
}) => {
  const classes = useStyles();
  const fileInput = useRef(null);

  return (
    <Card>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar
            className={classes.avatar}
            src={profilePictureUrl || profilePlaceholder}
          />
          <Typography
            color="textPrimary"
            variant="h5"
            fontWeight="500"
            gutterBottom
          >
            {firstName} {lastName}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            Funktion (Finanzen,...)
          </Typography>
          <Typography color="textSecondary" variant="body1">
            Rolle z.B. Gast, Member, Vorstand
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions className={classes.upload}>
        <input
          className={classes.fileInput}
          type="file"
          accept=".png,.jpg"
          ref={fileInput}
          onChange={(e) => pictureUploadHandler(e.target.files)}
        ></input>

        {showUploadProgress ? (
          <progress
            style={{ width: "70%" }}
            max="100"
            value={uploadProgress}
          ></progress>
        ) : (
          <Button
            className={classes.buttonStyle}
            fullWidth
            variant="text"
            onClick={() => fileInput.current.click()}
          >
            Upload picture
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

ProfileAvatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  pictureUploadHandler: PropTypes.func,
  showUploadProgress: PropTypes.bool,
  uploadProgress: PropTypes.number,
};

export default ProfileAvatar;

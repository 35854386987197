export const costTypeMain = [
  {
    abbreviationCode: "",
    labelValue: "sonstiges",
    labelName: "Sonstiges",
    description: "",
    subcategory: false,
    created_at: null,
    updated_at: null,
  },
  {
    abbreviationCode: "",
    labelValue: "verpflegung",
    labelName: "Verpflegung",
    description: "z.B. für Einkauf von Essen und Trinken",
    subcategory: true,
    created_at: null,
    updated_at: null,
  },
  /*{
    abbreviationCode: "",
    labelValue: "verbrauchsmaterial",
    labelName: "Verbrauchsmaterial",
    description: "",
    subcategory: true,
    created_at: null,
    updated_at: null,
  },
  {
    abbreviationCode: "",
    labelValue: "fahrtkosten",
    labelName: "Fahrtkosten/Reisekosten",
    description: "",
    subcategory: true,
    created_at: null,
    updated_at: null,
  },
  {
    abbreviationCode: "",
    labelValue: "marketing",
    labelName: "Marketing",
    description: "",
    subcategory: true,
    created_at: null,
    updated_at: null,
  },
  {
    abbreviationCode: "",
    labelValue: "eintrittskarten",
    labelName: "Eintrittskarten (Paintball, Skiliftkarte, Roomescape, ...",
    description: "",
    subcategory: true,
    created_at: null,
    updated_at: null,
  },
  {
    abbreviationCode: "",
    labelValue: "unterkunft",
    labelName: "Unterkunft",
    description: "",
    subcategory: true,
    created_at: null,
    updated_at: null,
  },
  {
    abbreviationCode: "",
    labelValue: "teilnahmegebuehr",
    labelName: "Teilnahmegebühr (Fee)",
    description: "",
    subcategory: true,
    created_at: null,
    updated_at: null,
  },*/
].sort((a, b) => (a.labelValue > b.labelValue ? 1 : -1));

import { unstable_createMuiStrictModeTheme as createMuiTheme , responsiveFontSizes } from "@material-ui/core";

let theme = createMuiTheme({
  palette: {
    background: {
      default: "#F4F6F8",
      blue: "#003b5c",
      lightgrey: "#F9F9F9",
    },
    primary: {
      main: "#003b5c",
    },
    secondary: {
      main: "#007DBA",
    },
    text: {
      primary: "#000",
      secondary: "#666",
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dividerFullWidth: {
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(
      1
    )}px ${theme.spacing(0)}px`,
    color: theme.palette.primary.main,
  },
  formControl: {
    margin: `${theme.spacing(1)}px 0`,
    minWidth: "100%",
  },
}));

export { useStyles };

import React from "react";

import { Grid, FormControl, InputLabel, Select } from "@material-ui/core";

import { useStyles } from "./CostTypeSelectStyles.js";

const CostTypeSelect = ({
  selectedValueObject,
  costTypeMainArray,
  costTypeSubArray,
  onSelectChangeHandler,
  useFormInputRefRegister,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item lg={6} md={6} xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel shrink>Kostenart</InputLabel>
            <Select
              notched
              native
              value={selectedValueObject.costTypeMain}
              onChange={onSelectChangeHandler}
              name="costTypeMain"
              label="Kostenart"
              inputRef={useFormInputRefRegister}
            >
              <option key={"costTypeMain"} aria-label="None" value="" />
              {costTypeMainArray.map((item, index) => (
                <option key={index} value={item.labelValue}>
                  {item.labelName}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {selectedValueObject.costTypeMain && costTypeSubArray.length !== 0 && (
          <Grid item lg={6} md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel shrink>Unterkostenart</InputLabel>

              <Select
                notched
                native
                value={selectedValueObject.costTypeSub}
                onChange={onSelectChangeHandler}
                name="costTypeSub"
                label="Unterkostenart"
                inputRef={useFormInputRefRegister}
                disabled={false}
              >
                <option key={"costTypeSub"} aria-label="None" value="" />
                {costTypeSubArray.map((item, index) => (
                  <option key={index} value={item.labelValue}>
                    {item.labelName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CostTypeSelect;

export const approvedby = [
  {
    labelValue: "basicpacket",
    labelName: "Basispacket (MiSi)",
    description: "",
    created_at: null,
    updated_at: null,
  },
  {
    labelValue: "projektleiter",
    labelName: "Projektleiter",
    description: "",
    created_at: null,
    updated_at: null,
  },
  {
    labelValue: "vorstand",
    labelName: "Vorstand",
    description: "",
    created_at: null,
    updated_at: null,
  },
  {
    labelValue: "vorstandbeschluss",
    labelName: "Vorstandbeschluss",
    description: "",
    created_at: null,
    updated_at: null,
  },
  {
    labelValue: "hvbeschluss",
    labelName: "HV-Beschluss",
    description: "",
    created_at: null,
    updated_at: null,
  },
].sort((a, b) => (a.labelValue > b.labelValue ? 1 : -1));

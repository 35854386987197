import React from "react";
import {
    CardHeader,
    Grid,
    Typography,
    CardActions,
    IconButton,
    Paper,
} from "@material-ui/core";

import {removeReceipDocumentFromCollection} from "../../../firebase/firestore/receipt";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";

import {useStyles} from "./ExpenseItemCardStyles";

const ExpenseItemCard = ({receipt, index}) => {
    const classes = useStyles();

    return (
        <React.Fragment key={index}>
            <Grid item lg={12} md={12} xs={12}>
                <Paper className={classes.cardStyleExpense}>
                    <CardHeader className={classes.Header}
                        /*         avatar={
                        <Avatar
                          size="small"
                          className={classes.expensesAvatar}
                        >
                          <ReceiptIcon />
                        </Avatar>
                      }
                      title="Ausgabe"
                      subheader={new Date(
                        receipt.invoiceDate
                      ).toLocaleDateString("de-DE")} */
                                title={
                                    <Typography
                                        sx={{fontSize: 10}}
                                        color="textSecondary"
                                        variant="body2"
                                        gutterBottom
                                    >
                                        {new Date(receipt.invoiceDate).toLocaleDateString("de-DE")}
                                    </Typography>
                                }
                                action={
                                    <>
                                        <IconButton
                                            size="small"
                                            className={classes.buttonStyleEditDelete}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            className={classes.buttonStyleEditDelete}
                                            onClick={() =>
                                                removeReceipDocumentFromCollection(receipt.uid, receipt.id)
                                            }
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </>
                                }
                    />

                    <div className={classes.blist}>
                        <Typography variant="body1" align="left">
                            {receipt.costTypeMain} für {receipt.paymentPurpose}
                        </Typography>
                    </div>

                    <div className={classes.blist}>
                        <Typography variant="body2" align="left">
                            <span className={classes.bold}>Details:</span> {receipt.paymentDescription}
                        </Typography>
                    </div>

                    <div className={classes.blist}>
                        <Typography variant="body2" align="left">
                            {receipt.startingPlace} {receipt.destination}
                        </Typography>
                    </div>

                    <CardActions className={classes.expenseActions}>
                        <IconButton size="small"
                                    className={classes.buttonStyleInfo}>
                            <InfoIcon/>
                        </IconButton>
                        <Typography variant="h6">
                            € {Number(receipt.amount).toFixed(2)}
                        </Typography>
                    </CardActions>
                </Paper>
            </Grid>
        </React.Fragment>
    );
};

export default ExpenseItemCard;
